@font-face {
  font-family: "Anton";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Anton-Regular.ttf") format("truetype");
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #3d7268; */
  /* 3d7268 */
  /* #fff4df */
  /* b8e9d7 */
  /* ebe0bb */
  /* ffed92 */
  /* fffdeb */
  /* fadac8 */
  /* fefdfb */
  /* 101010 */
  /* bcddf7 */
  /* edece8 */
  /* aec0ff */
  background-repeat: repeat;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select {
  /* 1 */
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
Restore the focus styles unset by the previous rule.
*/

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-size: 18px;
  margin-bottom: 12px;
}

h1,
h2 {
  font-size: 32px;
}

h3 {
  font-size: 18px;
}

button {
  background-color: transparent;
  background-image: none;
}

a {
  text-decoration: none;
  color: #fff;
}
